import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsys.myprofile.edit');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsys-myprofile-edit',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys/project/main/build/src/app/portal.lpsys.myprofile.edit/view.scss */
/* src/app/portal.lpsys.myprofile.edit/view.scss: no such file or directory */`],
})
export class PortalLpsysMyprofileEditComponent implements OnInit {
    public data: any = null;
    public field: any = '';
    public sub_field: any = '';
    public passwd: any = {};

    constructor(@Inject( Service) public service: Service) { }

    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        await this.load();
    }

    public async load() {
        const { data } = await wiz.call("load");
        this.data = data;
        if (!this.data.extra) this.data.extra = {};
        
        if (this.data.field) this.data.field = this.data.field.split(",");
        else this.data.field = [];
        
        if (this.data.sub_field) this.data.sub_field = this.data.sub_field.split(",");
        else this.data.sub_field = [];

        await this.service.render();
    }

    public async deleteBusinessCard() {
        this.data.extra.business_card = '';
        await this.service.render();
    }

    public async uploadBusinessCard() {
        let files = await this.service.file.select({ accept: 'image/*', multiple: false });
        if (files.length == 0) return;

        let fd = new FormData();
        fd.append('upload', files[0]);
        let fdurl = '/file/upload';
        const { url } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });
        this.data.extra.business_card = url;
        this.service.render();
    }

    public timestamp() {
        return new Date().getTime();
    }

    public async update() {
        await this.service.loading.show();
        let body = JSON.parse(JSON.stringify(this.data));
        body.field = body.field.join(",")
        body.sub_field = body.sub_field.join(",")
        const { code, data } = await wiz.call("update", { data: JSON.stringify(body) });
        this.passwd = {};

        await this.load();
        await this.service.loading.hide();
        await this.service.alert.show({ title: "마이페이지", message: "회원 정보가 변경되었습니다.", action: "확인", actionBtn: "success", cancel: false, status: "success" });
    }

    public async updatePassword() {
        if (this.passwd.change != this.passwd.repeat) {
            await this.alert("비밀번호가 일치하지 않습니다");
            return
        }

        if (this.passwd.change.length < 8) return await this.alert("8글자 이상의 비밀번호를 설정해주세요");
        if (this.passwd.change.search(/[a-z]/i) < 0) return await this.alert("비밀번호에 알파벳을 포함해주세요");
        if (this.passwd.change.search(/[0-9]/) < 0) return await this.alert("비밀번호에 숫자를 포함해주세요");

        await this.service.loading.show();
        let passwd: any = {
            current: this.service.auth.hash(this.passwd.current),
            change: this.service.auth.hash(this.passwd.change)
        }
        const { code } = await wiz.call("update_password", passwd);
        await this.service.loading.hide();
        if (code == 200) {
            await this.service.alert.show({ title: "마이페이지", message: "비밀번호가 변경되었습니다.", action: "확인", actionBtn: "success", cancel: false, status: "success" });
            this.passwd = {};
        } else {
            await this.alert("비밀번호를 확인해주세요");
        }
        await this.service.render();
    }

    public async alert(message) {
        await this.service.alert.show({ title: "마이페이지", message: message, action: "확인", actionBtn: "error", cancel: false, status: "error" });
    }

    public async add(key, item) {
        if (!item || (this.data[key] && this.data[key].includes(item))) return;
        if (this.data[key]) this.data[key].push(item);
        else this.data[key] = [item];
        this[key] = "";
        await this.service.render();
    }

    public async remove(key, target) {
        this.data[key].remove(target);
        await this.service.render();
    }
}

export default PortalLpsysMyprofileEditComponent;