import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.navbar');
import { OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-layout-navbar',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys/project/main/build/src/app/layout.navbar/view.scss */
body {
  width: 100%;
}

.block-page {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}`],
})
export class LayoutNavbarComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    @HostListener('document:click')
    public clickout() {
        this.service.navbar.toggle(true);
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

    public hasAuth(roles: string) {
        roles = roles.split(",");
        return roles.includes(this.service.auth.session.membership);
    }

    public activeClass(link: string) {
        if (this.isActive(link)) {
            return "inline-flex items-center border-b-2 border-stone-500 px-1 pt-1 text-sm font-medium text-gray-900";
        }
        return "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700";
    }

    public activeClassM(link: string) {
        if (this.isActive(link)) {
            return "block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700";
        }
        return "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800";
    }
}

export default LayoutNavbarComponent;