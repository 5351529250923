import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsys.myprofile.info');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsys-myprofile-info',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys/project/main/build/src/app/portal.lpsys.myprofile.info/view.scss */
/* src/app/portal.lpsys.myprofile.info/view.scss: no such file or directory */`],
})
export class PortalLpsysMyprofileInfoComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public data: any = [];
    public total: number = 0;

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        const { code, data } = await wiz.call("list");

        data.sort((a, b) => {
            let ac = 0;
            if (a.registered_date) ac = new Date(a.registered_date).getTime();
            let bc = 0;
            if (b.registered_date) bc = new Date(b.registered_date).getTime();
            if (ac > bc) return -1;
            if (ac < bc) return 1;
            return 0;
        });

        let res = [];
        this.total = 0;
        for (let i = 0; i < data.length; i++) {
            let rows = this.iterItems(data[i]);

            let total = 0;
            for (let j = 0; j < rows.length; j++) {
                let row = rows[j];

                row.meta = {};
                row.meta.sell1 = this.calc(row, 0);
                row.meta.sell2 = this.calc(row, 1);
                row.meta.sell3 = this.calc(row, 2);
                row.meta.total = 0;
                if (row.meta.sell1.amount) row.meta.total = row.meta.total + row.meta.sell1.amount * 1;
                if (row.meta.sell2.amount) row.meta.total = row.meta.total + row.meta.sell2.amount * 1;
                if (row.meta.sell3.amount) row.meta.total = row.meta.total + row.meta.sell3.amount * 1;
                total += row.meta.total;
            }

            for (let j = 0; j < rows.length; j++) {
                let row = rows[j];
                row.total_sell = total;
                res.push(row);
            }

            this.total += total;
        }

        this.data = res;
        await this.service.render();
    }

    public iterItems(item: any) {
        if (item.investment.length == 0) {
            let row: any = JSON.parse(JSON.stringify(item));
            row.investment = {};
            row._rowspan = 1;
            return [row];
        }

        let rows: any = [];
        for (let i = 0; i < item.investment.length; i++) {
            let row: any = JSON.parse(JSON.stringify(item));
            if (i == 0) row._rowspan = item.investment.length;
            row.investment = item.investment[i];
            rows.push(row);
        }
        return rows;
    }

    public translateStatus(item) {
        if (item.investment_status == '운용') return 'operating';
        if (item.investment_status == '해산') return 'exit';
        return 'formed';
    }

    public calc(item, target) {
        try {
            let investment_id = item.investment.id;
            let sells = item.member.extra.sell[investment_id];
            return sells[target];
        } catch (e) {
        }
        return {};
    }
}

export default PortalLpsysMyprofileInfoComponent;