import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.login');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-login',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys/project/main/build/src/app/page.login/view.scss */
.main-view {
  min-height: 100vh;
}`],
})
export class PageLoginComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(false, "/landing");
    }

    public view: string = 'login';

    public data: any = {
        email: '',
        password: ''
    };

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async login() {
        let user = JSON.parse(JSON.stringify(this.data));
        if (!user.password)
            delete user.password;
        else
            user.password = this.service.auth.hash(user.password);

        let { code, data } = await wiz.call("login", user);

        if (code == 200) {
            location.href = "/";
        } else if (code == 201) {
            this.view = "login";
            await this.service.render();
            setTimeout(() => {
                document.querySelector("input[type=password]").focus();
            }, 150);
        } else if (code == 301) {
            let isjoin = await this.alert(data, 'success', '닫기', '계정 만들기');
            if (!isjoin) return;
            this.view = "join.terms";
            await this.service.render();
        } else {
            await this.alert(data, 'error');
        }
    }

}

export default PageLoginComponent;