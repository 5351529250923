import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsys.widget.fund.item.docs');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import moment from 'moment';

@Component({
    selector: 'wiz-portal-lpsys-widget-fund-item-docs',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys/project/main/build/src/app/portal.lpsys.widget.fund.item.docs/view.scss */
/* src/app/portal.lpsys.widget.fund.item.docs/view.scss: no such file or directory */`],
})
export class PortalLpsysWidgetFundItemDocsComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() fund: any;
    @Input() status: any;

    public groupMap: any = { '결성': "결성중인 조합", '운용': '운용중인 조합', '해산': '중간정산/해산완료' };

    public async ngOnInit() {
        await this.service.init();

        if (!this.fund.extra.document) this.fund.extra.document = {};
        if (!this.fund.extra.document['결성']) this.fund.extra.document['결성'] = { name: '', items: [] };
        if (!this.fund.extra.document['운용']) this.fund.extra.document['운용'] = { name: '', items: [] };
        if (!this.fund.extra.document['해산']) this.fund.extra.document['해산'] = { name: '', items: [] };

        this.fund.extra.document = { ...this.fund.extra.document };
        for (let key in this.fund.extra.document) {
            if (!['결성', '운용', '해산'].includes(key)) {
                delete this.fund.extra.document[key];
            }
        }

        if (!this.status.extra.document)
            this.status.extra.document = {};
        if (!this.status.extra.document_time)
            this.status.extra.document_time = {};
        if (!this.status.extra.document_check)
            this.status.extra.document_check = {};

        await this.service.render();
    }

    public async upload(name) {
        let files = await this.service.file.select();
        if (files.length == 0) return;

        await this.service.loading.show();

        let fd = new FormData();
        fd.append('upload', files[0]);
        let fdurl = '/file/upload';
        const { url } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });

        await this.service.loading.hide();

        this.status.extra.document[name] = url;
        this.status.extra.document_time[name] = moment().format("YYYY-MM-DD HH:mm:ss");
        try {
            delete this.status.extra.document_check[name]
        } catch (e) {
        }

        await wiz.call("update", { info: JSON.stringify(this.status) });
        this.service.render();
    }

    public async alert(message) {
        await this.service.alert.show({ title: "조합참여", message: message, action: "확인", actionBtn: "error", cancel: false, status: "error" });
    }

    public statusParser() {
        if (this.fund.investment_status == '결성') return ['결성'];
        if (this.fund.investment_status == '운용') return ['운용', '결성'];
        return ['해산', '운용', '결성'];
    }
}

export default PortalLpsysWidgetFundItemDocsComponent;