import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsysadmin.widget.fund.info');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsysadmin-widget-fund-info',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys/project/main/build/src/app/portal.lpsysadmin.widget.fund.info/view.scss */
.form-control-flush {
  text-align: left !important;
}`],
})
export class PortalLpsysadminWidgetFundInfoComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() fund: any;
    @Input() investments: any;
    @Input() visibility: any;

    public async ngOnInit() {
        await this.service.init();
        await this.setVisibility();
        await this.service.render();

        this.fund.sum_investment = 0;
        for (let i = 0; i < this.investments.length; i++) {
            if (this.investments[i].investment_amount)
                this.fund.sum_investment += this.investments[i].investment_amount * 1;
        }

        this.fund.sum_investment = this.service.formatter.currency(this.fund.sum_investment);
        await this.service.render();
    }

    public async setVisibility() {
        this.visibility = { ...this.visibility };
        // this.visibility = { ...this.visibility, total_amount: true, gp_investment: true, sum_investment: true };
        // if (this.fund.investment_status == '운용') this.visibility = { ...this.visibility, name: true, gp_string: true, amount_per_share: true, type: true, category: true, unique_number: true, report_frequency: true, auditor: true, operating_period: true, total_amount: true, gp_investment: true, sum_investment: true }
        await this.service.render();
    }

    public async update() {
        let body = JSON.parse(JSON.stringify(this.fund));
        body.visibility = this.visibility;
        body.mgmt_rate = body.mgmt_rate.join(",");
        const { code, data } = await wiz.call("update", { info: JSON.stringify(body) });
        if (code == 200) await this.alert("저장되었습니다", "success");
        else if (code == 201) {
            await this.service.href(`/admin/fund/formed/${data}/info`);
            location.reload();
            return
        }
        else await this.alert("오류가 발생했습니다. 다시 저장해주세요.", "error");
        await this.service.render();
    }

    public async clearValue(obj, key) {
        obj[key] = null;
        await this.service.render();
    }

    public async upload(item, target) {
        let files = await this.service.file.select();
        if (files.length == 0) return;

        let fd = new FormData();
        fd.append('upload', files[0]);
        let fdurl = '/file/upload';
        const { url } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });
        item[target] = url;
        this.service.render();
    }

    public activeClass(target: string, status: string) {
        if (target == status) {
            return "group flex gap-x-3 mr-2 rounded-full bg-gray-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600";
        }
        return "group flex gap-x-3 rounded-md mr-2 py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600";
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
}

export default PortalLpsysadminWidgetFundInfoComponent;