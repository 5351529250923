import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.join');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-join',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys/project/main/build/src/app/page.join/view.scss */
/* src/app/page.join/view.scss: no such file or directory */`],
})
export class PageJoinComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(false, "/landing");
    }

    public field: any = '';
    public sub_field: any = '';
    public view: string = 'step1';

    public data: any = {
        email: '',
        password: '',
        field: [],
        sub_field: [],
        extra: {}
    };

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async step(view: string) {
        this.view = view;
        await this.service.render();
    }

    public async uploadBusinessCard() {
        let files = await this.service.file.select({ accept: 'image/*', multiple: false });
        if (files.length == 0) return;

        await this.service.loading.show();
        let fd = new FormData();
        fd.append('upload', files[0]);
        let fdurl = '/file/upload';
        const { url } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });

        const { code, data } = await wiz.call("parse_image", { path: url });

        if (!this.data.name) this.data.name = data.name;
        if (!this.data.company) this.data.company = data.company;
        if (!this.data.title) this.data.title = data.title;
        if (!this.data.phone) this.data.phone = data.phone;
        if (this.data.field.length == 0) this.data.field = [data.field];
        if (this.data.sub_field.length == 0) this.data.sub_field = [data.field];

        await this.service.loading.hide();

        this.data.extra.business_card = url;
        this.service.render();
    }

    public timestamp() {
        return new Date().getTime();
    }

    public async join_email_check() {
        let user = JSON.parse(JSON.stringify(this.data));
        delete user.password;
        let { code } = await wiz.call("login", user);
        if (code == 301) {
            this.data.verify = true;
            await this.emailAuthentication(this.data.email);
            await this.service.render();
            return;
        }

        await this.alert('사용할 수 없는 이메일입니다.');
    }

    public async emailAuthentication(email: string, showstatus: boolean = true) {
        await this.service.loading.show();
        let res = await wiz.call('emailAuthentication', { email });
        await this.service.loading.hide();
        if (!showstatus) return;
        if (res.code == 200) {
            await this.alert('이메일 인증 코드가 발송되었습니다', 'success');
        } else {
            await this.alert('이메일 인증 코드 발송중 오류가 발생했습니다', 'error');
        }
    }

    public async emailVerify(email: string, onetimepass: string) {
        await this.service.loading.show();
        let res = await wiz.call('emailVerify', { email, onetimepass });
        await this.service.loading.hide();
        if (res.code == 200) {
            this.data.verified = true;
            await this.service.render();
        } else {
            await this.alert(res.data, 'error');
        }
    }

    public async join() {
        if (this.data.name.length == 0) return await this.alert("이름을 입력해주세요");

        let password = this.data.password;
        let password_re = this.data.password_repeat;
        if (password.length < 8) return await this.alert("8글자 이상의 비밀번호를 설정해주세요");
        if (password.search(/[a-z]/i) < 0) return await this.alert("비밀번호에 알파벳을 포함해주세요");
        if (password.search(/[0-9]/) < 0) return await this.alert("비밀번호에 숫자를 포함해주세요");
        if (password != password_re) return await this.alert("비밀번호가 일치하지 않습니다");

        let user = JSON.parse(JSON.stringify(this.data));
        delete user.password_repeat;
        user.password = this.service.auth.hash(user.password);
        user.field = user.field.join(",")
        user.sub_field = user.sub_field.join(",")
        user.extra = JSON.stringify(user.extra);

        let { code } = await wiz.call("join", user);

        if (code == 200) {
            await this.alert("회원가입이 완료되었습니다.", "success");
            this.service.href("/login");
        } else {
            await this.alert(data);
        }
    }

    public async add(key, item) {
        if (!item || (this.data[key] && this.data[key].includes(item))) return;
        if (this.data[key] && this.data[key].length > 2) {
            await this.alert("최대 3개 까지만 등록 가능합니다.", "error");
            return;
        }
        if (this.data[key]) this.data[key].push(item);
        else this.data[key] = [item];
        this[key] = "";
        await this.service.render();
    }

    public async remove(key, target) {
        this.data[key].remove(target);
        await this.service.render();
    }
}

export default PageJoinComponent;